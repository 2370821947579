import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./usuariosAdmin.css"; // Importa el archivo CSS
import { Link } from "react-router-dom"; // Importa Link desde React Router
import TituloyFlecha from "../../components/TituloyFlecha";
import { use } from "i18next";

const UsuariosAdmin = () => {
  const { t } = useTranslation("global");
  const [usuarios, setUsuarios] = useState([]);
  const [estudiopiloto, setEstudiopiloto] = useState(true);
  const [orden, setOrden] = useState("fecha"); // Estado para el criterio de ordenación
  const [isLoading, setIsLoading] = useState(true); // Estado para mostrar el indicador de carga

  // const id = [
  //   1853, 2054, 2064, 2052, 1856, 2055, 2058, 1952, 1902, 2002, 1854, 1855,
  //   2057, 2056, 2053, 2103, 2209, 2202, 2203, 2205, 2204, 2207, 2208, 2206,
  //   2210, 2153, 2154, 2155, 2156, 2157, 2158, 2159, 2214, 2213, 2216, 2215,
  //   2220, 2217, 2221, 2257, 2212, 2219, 2258, 2225, 2259, 2302, 2218, 2352,
  //   2403, 2402, 2452, 2506, 2504, 2505, 2503, 2552, 2553
  // ];

  const checkIdInList = (usuario) => {
    return usuario.id_piloto && usuario.id_piloto.trim() !== "";
  };

  const isValidDate = (dateString) => {
    return !isNaN(Date.parse(dateString));
  };

  useEffect(() => {
    const fetchUsuarios = async () => {
      setIsLoading(true); // Muestra el indicador de carga
      try {
        const response = await axios.get(
          `${t("Recursos.ip")}/accounts/cuidadores`
        );

        const cuidadoresConDatos = await Promise.all(
          response.data.map(async (cuidador) => {
            const datos = await fetchData(cuidador.login);
            const sueno = datos[2];
            const pasos = datos[1];
            const pulsaciones = datos[0];
            return {
              ...cuidador,
              sueno,
              pasos,
              pulsaciones,
            };
          })
        );

        let cuidadoresOrdenados;
        if (orden === "fecha") {
          cuidadoresOrdenados = cuidadoresConDatos.sort((a, b) => {
            if (a.pasos === "N/A" && b.pasos !== "N/A") return 1;
            if (a.pasos !== "N/A" && b.pasos === "N/A") return -1;
            if (a.pasos === "N/A" && b.pasos === "N/A") return 0;
            return (
              new Date(isValidDate(b.pasos) ? b.pasos : 0) -
              new Date(isValidDate(a.pasos) ? a.pasos : 0)
            );
          });
        } else if (orden === "id") {
          cuidadoresOrdenados = cuidadoresConDatos.sort((a, b) => a.id - b.id);
        }

        setUsuarios(cuidadoresOrdenados);
        console.log(cuidadoresOrdenados)
      } catch (error) {
        console.error("Error al obtener los cuidadores:", error);
      } finally {
        setIsLoading(false); // Oculta el indicador de carga
      }
    };

    const fetchData = async (email) => {
      try {
        const response = await axios.get(
          `https://backend.talionis.eu:8443/api/accounts/getLastDatos`,
          {
            params: { email },
          }
        );
        // console.log("Repsuesta", response.data);
        return response.data;
      } catch (error) {
        console.error(`Error al obtener datos para el email ${email}:`, error);
        return "N/A";
      }
    };

    fetchUsuarios();
    // eslint-disable-next-line
  }, [t]);

  const handleOrder = (orden) => {
    setUsuarios(oldUsuarios => {
      let cuidadoresOrdenados;
      if (orden === "fecha") {
        cuidadoresOrdenados = oldUsuarios.sort((a, b) => {
          if (a.pasos === "N/A" && b.pasos !== "N/A") return 1;
          if (a.pasos !== "N/A" && b.pasos === "N/A") return -1;
          if (a.pasos === "N/A" && b.pasos === "N/A") return 0;
          return (
            new Date(isValidDate(b.pasos) ? b.pasos : 0) -
            new Date(isValidDate(a.pasos) ? a.pasos : 0)
          );
        });
      } else if (orden === "id") {
        cuidadoresOrdenados = oldUsuarios.sort((a, b) => a.id - b.id);
      }
      return cuidadoresOrdenados;
    });
    setOrden(orden);
  };



  const handleAction = (id) => {
    console.log(`Acción realizada para el cuidador con ID ${id}`);
  };

  const download_Pasos_CSV = async (id, nombre) => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/pasos/descargarPasos",
        {
          params: { cuidadorId: id },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${nombre}_pasos.csv`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const download_Sueno_CSV = async (id, nombre) => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/sueno/descargarSuenos",
        {
          params: { email: id },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${nombre}_sueño.csv`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const download_Pulsaciones_CSV = async (id, nombre) => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/pulsaciones/descargarPulsaciones",
        {
          params: { email: id },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${nombre}_pulsaciones.csv`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  return (
    <div>
      <TituloyFlecha title={t("Usuarios")} link={"/Admin"} />
      <div className="admin-page">
        <div className="table-containerAdmin">
          {/* <p>Bienvenido a la sección de administración.</p> */}
          {isLoading ? (
            <div className="spinner"></div>
          ) : (
            <>
            <div className="table-settings">
              <label style={{marginRight: "15px"}}>
                Estudio Piloto &nbsp;
                <input
                  type="checkbox"
                  checked={estudiopiloto}
                  onChange={(e) => setEstudiopiloto(e.target.checked)}
                />
              </label>
              <label>
                Ordenar por: &nbsp;
                <select value={orden} onChange={(e) => handleOrder(e.target.value)}>
                  <option value="fecha">Fecha de sincronización de pasos</option>
                  <option value="id">ID del usuario</option>
                </select>
              </label>
            </div>
            <table className="tableAdmin">
              <thead>
                <tr>
                  <th>ID</th>
                  {estudiopiloto ? <th>ID Piloto</th> : null}
                  <th>Nombre</th>
                  <th>Correo</th>
                  <th>Sueño</th>
                  <th>Pasos</th>
                  <th>Pulsaciones</th>
                  <th>Descargar datos</th>
                </tr>
              </thead>
              <tbody>
                {usuarios
                  .filter((usuario) => !estudiopiloto || checkIdInList(usuario))
                  .map((usuario) => (
                    <tr key={usuario.id}>
                      <td>{usuario.id}</td>
                      {estudiopiloto ? <td>{usuario.id_piloto}</td> : null}
                      <td>{usuario.nombre}</td>
                      <td>{usuario.login}</td>
                      <td>{usuario.sueno}</td>
                      <td>{usuario.pasos}</td>
                      <td>{usuario.pulsaciones}</td>
                      <td>
                        <div className="row-actions">
                          <Link to={`/IntentosAdmin/${usuario.id}`}>
                            <button onClick={() => handleAction(usuario.id)}>
                              Formularios
                            </button>
                          </Link>
                          <button
                            onClick={() =>
                              download_Sueno_CSV(usuario.login, usuario.nombre)
                            }
                          >
                            Sueño
                          </button>
                          <button
                            onClick={() =>
                              download_Pasos_CSV(usuario.id, usuario.nombre)
                            }
                          >
                            Pasos
                          </button>
                          <button
                            onClick={() =>
                              download_Pulsaciones_CSV(
                                usuario.login,
                                usuario.nombre
                              )
                            }
                          >
                            Pulsaciones
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            </>
          )}
        </div>
      </div>
      <br></br>
    </div>
  );
};

export default UsuariosAdmin;
