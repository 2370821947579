import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { createStore, PluginFunctions, SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';

import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import TituloyFlecha from "../../components/TituloyFlecha";
import { zoom } from "d3";

const PdfViewer = () => {
  const { pdfFileName } = useParams();
  const pdfPath = process.env.PUBLIC_URL + "/PDFs/" + pdfFileName;

  const ListaContenidos = [
    //Tecuido
    {
      nombre: "Cuidadodeotraspersonas.pdf",
      nombreFront: "El cuidado de\n otras personas",
    },
    {
      nombre: "FasesAdaptacionRol.pdf",
      nombreFront: "Fases de adaptación\n al rol de persona\n cuidadora ",
    },
    {
      nombre: "PrincipiosCuidado.pdf",
      nombreFront: "Principios básicos\n del cuidado de otras\n personas ",
    },
    {
      nombre: "TareaEmpatia.pdf",
      nombreFront: "Reflexiones de empatía:\n La historia de Alex ",
    },
    {
      nombre: "TareaRealidad.pdf",
      nombreFront: "Empatizar con la\n persona que cuidas.\n Viviendo su realidad",
    },
    {
      nombre: "TareaRespeto.pdf",
      nombreFront:
        "Fomentar el respeto y la\n dignidad a través de\n la historia de vida",
    },
    {
      nombre: "MatCompltema1.pdf",
      nombreFront: "Material complementario",
    },

    {
      nombre: "PlandeCuidados.pdf",
      nombreFront: "El plan de cuidados",
    },
    {
      nombre: "EjemploPlan.pdf",
      nombreFront: "Plan de cuidados\n semanal de María",
    },
    {
      nombre: "GuiaBreve.pdf",
      nombreFront: "Guía breve para elaborar\n un plan de cuidados",
    },
    {
      nombre: "RecursosPlan.pdf",
      nombreFront: "Recursos para la creación\n de un plan de cuidados",
    },
    {
      nombre: "TareasImportantes.pdf",
      nombreFront: "Selección de tareas\n importantes para\n el plan de cuidados",
    },
    {
      nombre: "TareaRueda.pdf",
      nombreFront: "La rueda del cuidado",
    },
    {
      nombre: "TareaSemanal.pdf",
      nombreFront: "Creación de un\n plan semanal",
    },
    {
      nombre: "EstrategiasCuidado.pdf",
      nombreFront: "Estrategias del cuidado\n de otras personas",
    },
    {
      nombre: "AdaptacionHogar.pdf",
      nombreFront: "Recomendaciones\n generales para la\n adaptación del hogar",
    },
    {
      nombre: "GuiaBreveProducto.pdf",
      nombreFront: "Guía breve para seleccionar\n un producto de apoyo ",
    },
    {
      nombre: "RecomendacionesEstrategias.pdf",
      nombreFront: "Recomendaciones sobre las\n estrategias de cuidado",
    },

    {
      nombre: "TareaEstrategias.pdf",
      nombreFront: "Desarrollo de estrategias\n personalizadas",
    },
    {
      nombre: "MatComplTema3.pdf",
      nombreFront: "Material complementario",
    },
    {
      nombre: "AdaptacionHogar.pdf",
      nombreFront:
        "Estrategias para las\n actividades\n básicas de la vida diaria ",
    },
    {
      nombre: "EstrategiasInstr.pdf",
      nombreFront:
        "Estrategias para las\n actividades\n instrumentales de la\n vida diaria ",
    },
    {
      nombre: "EstrategiasFisico.pdf",
      nombreFront:
        "Estrategias para el\n cuidado de personas\n con limitaciones\n a nivel físico",
    },
    {
      nombre: "EstrategiasCogn.pdf",
      nombreFront:
        "Estrategias para el\n cuidado de personas\n con limitaciones\n a nivel cognitivo",
    },
    {
      nombre: "EstrategiasOcioTeCuido.pdf",
      nombreFront:
        "Estrategias de cuidado\n enfocadas a actividades\n ocio y recreativas",
    },
    {
      nombre: "EstrategiasComunicacion.pdf",
      nombreFront:
        "Estrategias en la\n comunicación para el\n cuidado de otras personas",
    },
    //Mecuido
    {
      nombre: "CuidandonosParaCuidar.pdf",
      nombreFront: "Cuidándonos\n para cuidar",
    },
    {
      nombre: "SeñalesDificultadesAutocuidado.pdf",
      nombreFront: "Desafíos que\n dificultan el\n autocuidado",
    },
    {
      nombre: "DerechosPersonasCuidadoras.pdf",
      nombreFront: "Los derechos\n de las personas\n cuidadoras",
    },

    {
      nombre: "PrincipiosIntegralesAutocuidado.pdf",
      nombreFront: "Principios Integrales\n del autocuidado",
    },

    {
      nombre: "ExplorandoMiAutocuidado.pdf",
      nombreFront: "Explorando mi\n autocuidado",
    },
    {
      nombre: "IdentificandoAlarmasAutocuidado.pdf",
      nombreFront: "Identificando los\n desafíos en mi\n autocuidado",
    },
    {
      nombre: "MaterialComplementarioAutocuidado.pdf",
      nombreFront: "Material complementario ",
    },
    {
      nombre: "MaterialComplementario.pdf",
      nombreFront: "Material complementario",
    },
    {
      nombre: "MisHabitosSaludable.pdf",
      nombreFront: "Mis hábitos de\n vida saludable",
    },
    {
      nombre: "GestionSalud.pdf",
      nombreFront: "Estrategias para\n la gestión de\n mi salud",
    },
    {
      nombre: "HabitosAlimenticios.pdf",
      nombreFront: "Hábitos alimenticios",
    },
    {
      nombre: "HabitosVidaSaludable.pdf",
      nombreFront: "Hábitos de vida\n saludable para mejorar\n la calidad de vida",
    },
    {
      nombre: "ActividadesSignificativas.pdf",
      nombreFront: "La importancia de\n lo significativo en\n nuestra vida diaria",
    },
    {
      nombre: "ActividadFisica.pdf",
      nombreFront: "Promoviendo la\n actividad física en\n la vida diaria",
    },
    {
      nombre: "AdaptacionEntornos.pdf",
      nombreFront: "Pautas para crear\n un ambiente de sueño",
    },
    {
      nombre: "CalidadSueno.pdf",
      nombreFront:
        "¿Cómo mejorar tu\n descanso y sueño?\n Pautas y recomendaciones",
    },
    {
      nombre: "DescubriendoActividadesSignificativas.pdf",
      nombreFront: "Descubriendo mis\n actividades significativas",
    },
    {
      nombre: "EjercicioSeguro.pdf",
      nombreFront: "Actividad física\n segura",
    },

    {
      nombre: "HigienePostural.pdf",
      nombreFront: "Higiene postural y\n ergonomía",
    },
    {
      nombre: "HigieneSueno.pdf",
      nombreFront: "Recomendaciones para\n una buena higiene\n del sueño",
    },
    {
      nombre: "MantenerRutinaSueno.pdf",
      nombreFront:
        "Estrategias para\n mantener y establecer\n una rutina del sueño",
    },
    {
      nombre: "MantenerseActivos.pdf",
      nombreFront: "Cómo mantener\n la motivación para\n hacer actividad física",
    },
    {
      nombre: "HerramientasPapel.pdf",
      nombreFront: "Herramientas para\n la planificación\n en papel",
    },
    {
      nombre: "HerramientasTIC.pdf",
      nombreFront: "Herramientas tecnológicas\n para la planificación",
    },
    {
      nombre: "LineaTiempo.pdf",
      nombreFront: "Mi línea del\n tiempo",
    },
    {
      nombre: "MaterialComplementarioPlanificado.pdf",
      nombreFront: "Material complementario",
    },
    {
      nombre: "PasosRutina.pdf",
      nombreFront: "Pasos para planificar\n mi rutina",
    },
    {
      nombre: "PlanificacionVidaDiaria.pdf",
      nombreFront: "La planificación en\n la vida diaria",
    },
    {
      nombre: "CreacionHorario.pdf",
      nombreFront: "Mi horario semanal",
    },
    {
      nombre: "EstrategiasBasicas.pdf",
      nombreFront: "Estrategias para las actividades básicas para la vida diaria",
    },
  ];

  const obtenerNombreFront = (nombreParametro) => {
    for (let i = 0; i < ListaContenidos.length; i++) {
      if (ListaContenidos[i].nombre === nombreParametro) {
        return ListaContenidos[i].nombreFront;
      }
    }
    return null;
  };



  const useCustomZoomPlugin = () => {
    const store = React.useMemo(() => createStore({}), []);
    
    return {
      install: (pluginFunctions) => {
        store.update('zoom', pluginFunctions.zoom);
      },
      zoomTo: (scale) => {
        const zoom = store.get('zoom');
        if (zoom) {
          // Zoom to that scale
          zoom(scale);
        }
      },
    };
  };

  // plugins
  const customZoomPluginInstance = useCustomZoomPlugin();
  const { zoomTo } = customZoomPluginInstance;

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const handleDocuemntLoad = () => {
    zoomTo(SpecialZoomLevel.ActualSize);
    // SpecialZoomLevel.ActualSize
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <TituloyFlecha title={obtenerNombreFront(pdfFileName)} link={"atras"} />
      <div
        style={{
          width: "95%",
          height: "80vh",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: "none",
          marginBottom: "30px",
          position: "relative"
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#fafcfc',
            border: '2px solid rgba(0, 0, 0, 0.3)',
            borderRadius: '5px',
            bottom: '16px',
            display: 'flex',
            left: '50%',
            padding: '4px 8px',
            position: 'absolute',
            transform: 'translate(-50%, 0)',
            zIndex: 1,
          }}
        >
          <Toolbar>
            {(props) => {
              const {
                CurrentPageInput,
                Download,
                EnterFullScreen,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                Print,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomOut />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <ZoomIn />
                  </div>
                  <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: '0px 2px', width: '4rem' }}>
                    <CurrentPageInput />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    / <NumberOfPages />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage />
                  </div>
                  <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                    <EnterFullScreen />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <Download />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <Print />
                  </div>
                </>
              );
            }}
          </Toolbar>
        </div>
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
            width: '100%',
            height: '100%',
          }}
        >
          <Viewer
            fileUrl={pdfPath}
            plugins={[toolbarPluginInstance, customZoomPluginInstance]}
            onDocumentLoad={handleDocuemntLoad}
          />
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
